import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  PageHeader,
  BoxIntroDecor,
  BoxPoster,
  Poster,
} from "../components/styles"

import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

export const query = graphql`
  query {
    poster: file(relativePath: { eq: "keynotes/poster.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 488, maxHeight: 564) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
const Keynotes = ({ data }) => {
  return (
    <Layout>
      <SEO title="Keynotes" />
      <Grid container alignItems="center" style={{ margin: "0 0 84px 0" }}>
        <Grid item xs={12} md={6}>
          <BoxIntroDecor>
            <PageHeader variant="h1">Keynotes</PageHeader>
            <Typography>
              I'm currently taking a break from delivering keynote presentations.
            </Typography>
            <Typography>
              Over the years however I've delivered approximately 400 keynote presentations across Australia, Vietnam, Singapore, Malaysia, Thailand, Nauru, Hong Kong and New Zealand.
            </Typography>
            <Typography>
              My clients have included everyone from BHP and Microsoft to my local primary school down the road.
            </Typography>
            <Typography>
              If you'd like to consider me for a keynote presentation, email me and I'll let you know when I'll be back keynoting again.
            </Typography>
          </BoxIntroDecor>
        </Grid>
        <Grid item xs={12} md={6}>
          <BoxPoster>
            <Poster fluid={data.poster.childImageSharp.fluid} />
          </BoxPoster>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Keynotes
